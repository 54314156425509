import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs/internal/Subscription';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {

  user$ = this.store.getUser();
  userId;

  private subscriptions$: Subscription[] = [];
  private BASE_API_URL = environment.apiURL;

  constructor(
    private http: HttpClient,
    private store: StoreService
  ) {
    this.subscriptions$.push(
      this.user$.subscribe(res => {
        if (res) {
          this.userId = res.authId;
        }
      })
    );
  }

  public get<T>(url: string, params = {}, options?: {}, isAuthorized = true): Observable<T> {
    if (isAuthorized) {
      const headers: HttpHeaders = new HttpHeaders({
        authorization: this.userId
      });
      options = { headers, params };
    } else {
      options = { params }
    }
    return this.http.get<T>(this.getUrl(url), options);
  }

  public getAll<T>(url: string): Observable<T> {
    return this.http.get<T>(this.getUrl(url));
  }

  public post<T>(url: string, body: any, options?: {}, isAuthorized = true): Observable<T> {
    if (isAuthorized) {
      const headers: HttpHeaders = new HttpHeaders({
        authorization: this.userId
      });
      options = { headers };
    }
    return this.http.post<T>(this.getUrl(url), body, options);
  }

  public update<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(this.getUrl(url), body);
  }

  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.getUrl(url));
  }

  private getUrl(url) {
    return this.BASE_API_URL + url;
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(sub => sub.unsubscribe());
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-range-filter-dropdown',
  templateUrl: './range-filter-dropdown.component.html',
  styles: []
})
export class RangeFilterDropdownComponent implements OnInit {

  @Output() filterSelected: EventEmitter<any> = new EventEmitter();

  @Input() defaultLabelText = '';

  labelText = '';

  constructor() { }

  ngOnInit() {
    this.labelText = this.defaultLabelText;
  }

  onOptionSelection(fromDate, toDate) {
    this.filterSelected.emit({
      startDate: fromDate,
      endDate: toDate
    });

    this.labelText = fromDate && toDate ? fromDate + ' to ' + toDate : this.defaultLabelText;
  }

  onDateRangeSelected(event) {
    if (event.startDate && event.endDate) {
      const startDate = event.startDate.year + '-' + event.startDate.month + '-' + event.startDate.day;
      const endDate = event.endDate.year + '-' + event.endDate.month + '-' + event.endDate.day;
      this.onOptionSelection(startDate, endDate);
    }
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbDatepickerNavigateEvent, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styles: []
})
export class MonthPickerComponent implements OnInit {

  @Output() monthSelected: EventEmitter<any> = new EventEmitter();
  @Input() minDate = { month: (new Date()).getMonth() + 1, year: (new Date()).getFullYear(), day: 1 };

  constructor() { }

  ngOnInit() {
  }

  onDateNavigate($event: NgbDatepickerNavigateEvent) {
    const fromDate = new Date($event.next.year, $event.next.month - 1, 1);
    const toDate = new Date($event.next.year, $event.next.month, 0);
    const startDate = new NgbDate(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      fromDate.getDate()
    );
    const endDate = new NgbDate(
      toDate.getFullYear(),
      toDate.getMonth() + 1,
      toDate.getDate()
    );
    this.monthSelected.emit({
      startDate,
      endDate
    });
  }
}

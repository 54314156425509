import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ContentLoaderComponent } from './components/content-loader/content-loader.component';
import { WeekPickerComponent } from './components/week-picker/week-picker.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { RangeFilterDropdownComponent } from './components/range-filter-dropdown/range-filter-dropdown.component';
import { SalespersonFilterMultiselectComponent } from './components/salesperson-filter-multiselect/salesperson-filter-multiselect.component';

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    SpinnerComponent,
    ContentLoaderComponent,
    WeekPickerComponent,
    MonthPickerComponent,
    DateRangePickerComponent,
    RangeFilterDropdownComponent,
    SalespersonFilterMultiselectComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    TranslateModule,
    NgSelectModule,
    FormsModule
  ],
  exports: [
    SidebarComponent,
    NavbarComponent,
    SpinnerComponent,
    TranslateModule,
    ContentLoaderComponent,
    WeekPickerComponent,
    MonthPickerComponent,
    DateRangePickerComponent,
    RangeFilterDropdownComponent,
    SalespersonFilterMultiselectComponent
  ],
  providers: [
  ]
})
export class SharedModule { }

import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { StoreService } from '../../../core/services/store.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: []
})
export class NavbarComponent implements OnInit {

  showMobileNavbar = false;
  showMinimizedSidebar = false;
  user$ = this.storeService.getUser();

  constructor(
    private storeService: StoreService,
    private renderer: Renderer2,
    private authService: AuthenticationService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {}

  toggleMobileNavbar() {
    this.showMobileNavbar = !this.showMobileNavbar;
  }

  toggleMinimizedSidebar() {
    this.showMinimizedSidebar = !this.showMinimizedSidebar;
    if (this.showMinimizedSidebar) {
      this.renderer.addClass(this.document.body, 'sidebar-mobile-main');
    } else {
      this.renderer.removeClass(this.document.body, 'sidebar-mobile-main');
    }
  }

  logout() {
    this.authService.logout();
  }

  navigateToSettings() {
    const url = '/settings/profile';
    this.router.navigateByUrl(url);
  }
}

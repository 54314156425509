import { Component, OnInit } from '@angular/core';
import { RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, Router } from '@angular/router';
import { AuthenticationService } from './core/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LOCALSTORAGE_AUTH } from './core/constants/app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isLazyRouteIndicatorEnabled: boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public translate: TranslateService,
  ) {
    this.showLazyLoadedModulesIndicator();
    this.setLanguage();
  }

  ngOnInit() {
    this.checkInitialAuthentication();
  }

  setLanguage() {
    this.translate.setDefaultLang('sv');
  }

  checkInitialAuthentication() {
    const auth = localStorage.getItem(LOCALSTORAGE_AUTH);
    if (!auth) {
      this.router.navigate(['/login']);
      return;
    }
    this.authService.setLoggedInUserData(JSON.parse(auth));
  }

  private showLazyLoadedModulesIndicator() {
    this.isLazyRouteIndicatorEnabled = false;

    // As the router loads modules asynchronously (via loadChildren), we're going to
    // keep track of how many asynchronous requests are currently active. If there is
    // at least one pending load request, we'll show the indicator.
    let asyncLoadCount = 0;

    // The Router emits special events for "loadChildren" configuration loading. We
    // just need to listen for the Start and End events in order to determine if we
    // have any pending configuration requests.
    this.router.events.subscribe(
      (event: RouterEvent): void => {
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++;
        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--;
        }

        // If there is at least one pending asynchronous config load request,
        // then let's show the loading indicator.
        this.isLazyRouteIndicatorEnabled = !!asyncLoadCount;
      }
    );
  }
}

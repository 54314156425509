import { Component, AfterViewInit, ViewChild, Output, EventEmitter, Input, enableProdMode } from '@angular/core';
import { NgbDate, NgbDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

if (environment.production) {
  enableProdMode();
}

@Component({
    selector: 'app-week-picker',
    templateUrl: './week-picker.component.html',
    styles: [],
})

export class WeekPickerComponent implements AfterViewInit {
    @Output() weekSelected: EventEmitter < any > = new EventEmitter();

    fromDate: NgbDate;
    toDate: NgbDate;
    showCalender = false;
    weekText = 'Select Week';

    @ViewChild(NgbDatepicker, { static: true }) datePicker:NgbDatepicker;

    constructor(private ngbCalendar: NgbCalendar) {

    }

    ngAfterViewInit() {

        if (this.fromDate) {
            setTimeout(() => {
                this.datePicker.navigateTo(this.fromDate);
            });
        }
    }

    ngOnChanges(changes) {
        var newDate;
        if (typeof changes.minDate.currentValue == "object") {
            this.onDateSelection(changes.minDate.currentValue);
        } else if (typeof changes.minDate.currentValue == "string") {
            newDate = new Date(changes.minDate.currentValue);
            var ngbdate = new NgbDate(newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate());
            this.onDateSelection(ngbdate);

        }
    }

    ngOnInit() {
       
    }

    onDateSelection(date: NgbDate) {

        let fromDate = new Date(date.year + '-' + date.month + '-' + date.day);
        const time = fromDate.getDay() ? fromDate.getDay() - 1 : 6;
        fromDate = new Date(fromDate.getTime() - time * 24 * 60 * 60 * 1000);
        this.fromDate = new NgbDate(
            fromDate.getFullYear(),
            fromDate.getMonth() + 1,
            fromDate.getDate()
        );
        const toDate = new Date(fromDate.getTime() + 6 * 24 * 60 * 60 * 1000);
        this.toDate = new NgbDate(
            toDate.getFullYear(),
            toDate.getMonth() + 1,
            toDate.getDate()
        );
        this.weekSelected.emit({
            startDate: this.fromDate,
            endDate: this.toDate
        });
        this.toggleCalendarVisibility();
        this.calculateWeekFromDate(fromDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return (
            date.equals(this.fromDate) ||
            date.equals(this.toDate) ||
            this.isInside(date)
        );
    }

    calculateWeekFromDate(date: any) {
        const time = new Date(date).getTime() + 4 * 24 * 60 * 60 * 1000;
        const firstDay = new Date(date.getFullYear() + '-1-1');
        const week = Math.floor(Math.round((time - firstDay.getTime()) / 86400000) / 7) + 1;
        this.weekText = 'Week ' + week + ', ' + date.getFullYear();
        return this.weekText;
    }

    calculateDateFromWeekAndYear(week: number, year: number) {
        const firstDay = new Date(year + '-1-4');
        const date = new Date(
            firstDay.getTime() + (week - 1) * 7 * 24 * 60 * 60 * 1000
        );
        const selectDate = new NgbDate(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        );
        this.onDateSelection(selectDate);
    }

    toggleCalendarVisibility() {
        this.showCalender = !this.showCalender;
    }

}
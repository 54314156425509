import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StoreService } from './store.service';
import { skipWhile } from 'rxjs/internal/operators/skipWhile';


@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

    private user$ = this.storeService.getUser();

    constructor(
        private router: Router,
        private storeService: StoreService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.user$
        .pipe(skipWhile(res => res === null))
        .subscribe(user => {
            const currentUser = user;
            if (currentUser) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            // authorised so return true
            return true;
        }
        });
        return true;
    }

    private loadUser() {
     return this.user$
      .pipe(skipWhile(res => res === null))
      .subscribe(user => {
        return user;
      });
    }
}

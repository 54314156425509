import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { StoreService } from './store.service';
import { UsersService } from './users.service';
import { Role } from '../../models/types/roles';
import { ToastrService } from 'ngx-toastr';
import { SharedUtilsService } from '../../shared/services/shared-utils.service';
import { LOCALSTORAGE_AUTH } from '../constants/app.constants';
import { User } from 'src/app/models/user.model';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    userData: User;
    crm$ = this.storeService.getActiveCRM();
    activeCRM;

    private subscriptions$: Subscription[] = [];
    isLoadingUsers: boolean;

    constructor(
        public firestore: AngularFirestore,
        public fireAuth: AngularFireAuth,
        public router: Router,
        private storeService: StoreService,
        private usersService: UsersService,
        private toastr: ToastrService,
        private sharedUtils: SharedUtilsService
    ) {}

    // User sign in
    signIn(email, password): Promise < any > {
        return this.fireAuth.auth.signInWithEmailAndPassword(email, password);
    }

    // user signup with email and password
    signUp(email, password): Promise < any > {
        return this.fireAuth.auth.createUserWithEmailAndPassword(email, password);
    }

    // user password reset
    resetPassword(newPassword): Promise < any > {
        return this.fireAuth.auth.currentUser.updatePassword(newPassword);
    }

    // Send email verfificaiton for new user sign up
    sendVerificationMail() {
        return this.fireAuth.auth.currentUser.sendEmailVerification();
    }

    setLoggedInUserData(user) {
        this.isLoadingUsers = true;
        this.usersService.getActiveCRM()
            .then(res => {
                if (res.exists) {
                    this.activeCRM = "users";
                    this.isLoadingUsers = false;
                    this.storeService.setActiveCRM("users");

                    if (res.data().is_dynamics_crm_enabled) {
                        this.storeService.setActiveCRM("dynamics");
                        this.activeCRM = "dynamics";
                    }
                    this.usersService.getUserById(user.uid, this.activeCRM)
                        .finally(() => this.storeService.setIsBusy(false))
                        .then(res => {
                            if (res.exists) {
                                const userData = this.usersService.getMappedUserData(res.data().account_details, user.uid);

                                if (userData.role.toLowerCase() !== Role.ROLE_ADMIN.toString().toLowerCase() &&
                                    userData.role.toLowerCase() !== Role.ROLE_SALES_MANAGER.toString().toLowerCase() &&
                                    userData.role.toLowerCase() !== Role.ROLE_TEAM_LEADER.toString().toLowerCase()) {
                                    this.toastr.warning('Tillträde beviljas ej!');
                                    this.logout();
                                    return;
                                }

                                // set user data and authentication status in store
                                this.userData = userData;
                                this.storeService.setUser(userData);
                                this.storeService.setIsAuthenticated(true);
                            } else {
                                this.toastr.error('Unable to find user!');
                            }
                        })
                        .catch(error => {
                            this.sharedUtils.handleNetworkCallError(error);
                        });
                }
            });
    }

    logout() {
        return this.fireAuth.auth.signOut().then(() => {
            localStorage.removeItem(LOCALSTORAGE_AUTH);
            this.storeService.resetStore();
            this.router.navigate(['/login']);
        });
    }
}
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EndpointsService {

  constructor() {
  }

  /**
   * All employees lifetime statistics url
   */
  getLifeTimeStatisticsUrl(): string {
    return '/getEmployeesLifetimeStats';
  }

  /**
   * All employees lifetime statistics report csv url
   */
  getLifeTimeStatisticsCsvUrl(): string {
    return '/getLifetimeStatsCSVReport';
  }

    /**
   * All employees lifetime ongoing statistics report csv url
   */
  getOngoingStatisticsCsvUrl(): string {
    return '/getOngoingStatsCSVReport';
  }

  /**
   * All employees current goals progress
   */
  getUserGoalsProgressUrl(): string {
    return '/getAllUsersGoals';
  }

  /**
   * All employees current goals progress report csv url
   */
  getUserGoalsProgressCsvUrl(): string {
    return '/getAllUsersGoalReport';
  }

  /**
   * Disable user url
   */
  getDisableUserUrl(): string {
    return '/disableUser';
  }

  getSyncCompanies(): string {
    return '/syncCompaniesOnClick';
  }
  

  getDeactivateUserUrl(): string {
    return '/deactivateUser';
  }

  /**
   * Create user url
   */
  getCreateUserUrl(): string {
    return '/createUser';
  }

  /**
   * Update user url
   */
  getUpdateUserUrl(): string {
    return '/updateUser';
  }

  getUpdateUserProfileUrl(): string {
    return '/updateUserProfile';
  }

  /**
   * Change User Password
   */
  getChangePasswordUrl(): string {
    return '/changePassword';
  }

  /**
   * Update Goals url
   */
  getUpdateGoalUrl(): string {
    return '/updateGoal';
  }

   /**
   * Update Goals url
   */
  getCustomGoalsUrl(): string {
    return '/setUserCustomGoals';
  }

  /**
   * Data encryption url
   */
  getdataEncryptionUrl(): string {
    return '/encryptdata';
  }

  /**
   * Data decryption url
   */
  getDataDecryptionUrl(): string {
    return '/decryptdata ';
  }

  /**
   * Create Competition url
   */
  getCreateCompetitionUrl(): string {
    return '/createUserCompetition';
  }

  /**
   * Update notifications url
   */
  getUpdateNotificationUrl(): string {
    return '/updateNotification';
  }

  getTopThreeRankedUsersCompetitions(): string{
    return '/getTopThreeRankedUsersCompetitions';
  }

  getAllUsersStatsAndTrophies(): string{
    return '/getAllUsersStatsAndTrophies';
  }

  getOngoingCompetitionsData(): string{
    return '/getOngoingCompetitionsData';
  }
}

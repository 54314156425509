import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { StoreService } from '../../../core/services/store.service';
import { Role } from 'src/app/models/types/roles';
import { UsersService } from '../../../core/services/users.service';
import { User } from '../../../models/user.model';
import { SharedUtilsService } from 'src/app/shared/services/shared-utils.service';

@Component({
    selector: 'app-salesperson-filter-multiselect',
    templateUrl: './salesperson-filter-multiselect.component.html',
    styles: []
})
export class SalespersonFilterMultiselectComponent implements OnInit {

    @Input() usersList = [];
    @Input() includeAllOption = false;
    @Input() includeNames = false;

    @Output() usersSelected: EventEmitter < any > = new EventEmitter();

    usersList$ = this.store.getUsersList();
    assignedIds = [];

    private subscriptions$: Subscription[] = [];
    crm$ = this.store.getActiveCRM();
    activeCRM;

    constructor(
        private store: StoreService,
        private usersService: UsersService,
        private sharedUtils: SharedUtilsService
    ) {
        this.subscriptions$.push(
            this.crm$.subscribe(res => {
                if (res) {
                    this.activeCRM = res;
                }
            })
        );
    }

    ngOnInit() {
        if (this.usersList.length < 1) {
            this.getUsers();
        }
    }

    onChangeUsersList() {
        if (!this.includeNames) {
            this.usersSelected.emit(this.assignedIds);
        } else {
            const list = this.usersList.reduce((reducedList, e) => {
                if (this.assignedIds.includes(e.id)) {
                    reducedList.push({
                        name: e.fullName,
                        uid: e.id
                    });
                }
                return reducedList;
            }, []);
            this.usersSelected.emit(list);
        }
    }

    private getUsers() {
        // If users list has already been loaded use that list; otherwise load users
        this.subscriptions$.push(
            this.usersList$.subscribe(usersList => {
                if (usersList.length > 0) {
                    this.usersList = usersList.filter((user: User) => user.role === Role.ROLE_SALES_PERSON.toString() || Role.ROLE_TEAM_LEADER.toString());
                } else {
                    this.usersService.getUsersAccountDetailsList(this.activeCRM)
                        .subscribe(res => {
                            const users = res.reduce((userList, e) => {
                                const user: User = this.usersService.getMappedUserData(e.payload.doc.data().account_details);
                                if ((user.role === Role.ROLE_SALES_PERSON.toString() || user.role === Role.ROLE_TEAM_LEADER.toString()) && !user.isTrashed) {
                                    userList.push({
                                        id: e.payload.doc.id,
                                        ...user
                                    });
                                }
                                return userList;
                            }, []);
                            this.usersList = users;
                            this.store.setUsersList(this.usersList);
                        }, error => {
                            this.sharedUtils.handleNetworkCallError(error);
                        });
                }
            })
        );
    }

}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private isBusySubject = new BehaviorSubject<boolean>(false);
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  private userSubject = new BehaviorSubject<any>(null);
  private usersListSubject = new BehaviorSubject<any>([]);
  private crmSubject = new BehaviorSubject<any>(null);

  constructor() {}

  /**
   * Change busy loader status
   */
  setIsBusy(isBusy: boolean): void {
    this.isBusySubject.next(isBusy);
  }

  /**
   * Returns only the observable part of isBusySubject
   */
  getIsBusy(): Observable<boolean> {
    return this.isBusySubject.asObservable();
  }

  /**
   * Set authentication status true/false
   */
  setIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticatedSubject.next(isAuthenticated);
  }

  /**
   * Returns only the observable part of isAuthenticatedSubject
   */
  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  /**
   * Set user information
   */
  setUser(user: any): void {
    this.userSubject.next(user);
  }

  /**
   * Returns only the observable part of crm
   */
  getActiveCRM(): Observable<any> {
    return this.crmSubject.asObservable();
  }

  /**
   * Set user information
   */
  setActiveCRM(crm: any): void {
    this.crmSubject.next(crm);
  }

   /**
   * Returns only the observable part of userSubject
   */
  getUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  /**
   * Set user information
   */
  setUsersList(userList: Array<User>): void {
    this.usersListSubject.next(userList);
  }

  /**
   * Returns only the observable part of userSubject
   */
  getUsersList(): Observable<any> {
    return this.usersListSubject.asObservable();
  }

  /**
   * Reset's store to initial state
   */
  resetStore(): void {
    this.isBusySubject.next(false);
    this.isAuthenticatedSubject.next(false);
    this.userSubject.next(null);
    this.usersListSubject.next([]);
  }
}

<div class="text-dark font-weight-semibold">
  {{'common.filter' | translate}}:
</div>
<div class="mb-2 form-group">
  <ng-select 
    [items]="usersList" 
    [placeholder]="('common.all' | translate)" 
    [bindLabel]="'fullName'" 
    [bindValue]="'id'" 
    [(ngModel)]="assignedIds" 
    [multiple]="true"
    [selectableGroup]="true" 
    [selectableGroupAsModel]="false" 
    [closeOnSelect]="false"
    (change)="onChangeUsersList()">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>
      {{item.fullName}}
    </ng-template>
  </ng-select>
</div>

<div id="week-datepicker-container" class="text-grey-700">
  <input type="text" class="form-control" [value]="weekText" readonly (click)="toggleCalendarVisibility()">  
  <ngb-datepicker *ngIf="showCalender" (dateSelect)="onDateSelection($event)" [showWeekNumbers]="true" [outsideDays]="outsideDays"
    [maxDate]="maxDate"  [minDate]="minDate" [dayTemplate]="t">
  </ngb-datepicker>
</div>
<ng-template #t let-date let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)">
    {{ date.day }}
  </span>
</ng-template>

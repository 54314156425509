<!-- Header with logos -->
<div class="navbar-header d-none d-md-flex align-items-md-center">
  <h1 class="title mb-0 ml-3 p-1">
    Workleague Admin
  </h1>
</div>
<!-- /header with logos -->

<!-- Mobile controls -->
<div class="d-flex flex-1 d-md-none">
  <div class="navbar-brand mr-auto">
    <h1 class="title mb-0 ml-3 p-1">
      Workleague Admin
    </h1>
  </div>

  <button class="navbar-toggler" type="button" (click)="toggleMinimizedSidebar()">
    <i class="icon-tab"></i>
  </button>
  <button class="navbar-toggler" type="button" (click)="toggleMobileNavbar()">
    <i class="icon-paragraph-justify3"></i>
  </button>
</div>
<!-- /mobile controls -->

<!-- Navbar content -->
<div class="collapse navbar-collapse" id="navbar-mobile" [class.show]="showMobileNavbar">
  <span class="navbar-text d-block d-xl-inline-block p-0"> </span>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item dropdown dropdown-user" ngbDropdown placement="bottom-right">
      <span class="navbar-nav-link dropdown-toggle" ngbDropdownToggle>
        <span *ngIf="(user$ | async)">
         <i class="icon-user pr-1"></i> {{(user$ | async).firstName}}
        </span>
      </span>
      <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <span *ngIf="(user$ | async)">
          <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToSettings()"><i class="icon-gear"></i>
             {{'settings.profile_settings' | translate}}</a>
        </span>
        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"><i class="icon-switch2"></i> 
          {{'app.logout' | translate}}</a>
      </div>
    </li>
  </ul>
</div>
<!-- /navbar content -->

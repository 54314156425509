import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ShellComponent } from './core/components/shell/shell.component';
import { Role } from './models/types/roles';
import { RoleGuard } from './core/services/role.guard';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      {
        path: 'users',
        canLoad: [],
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'reports',
        canLoad: [],
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'goals',
        canLoad: [],
        loadChildren: () => import('./goals/goals.module').then(m => m.GoalsModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'competitions',
        canLoad: [],
        loadChildren: () => import('./competitions/competitions.module').then(m => m.CompetitionsModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'notifications',
        canLoad: [],
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'coach',
        canLoad: [],
        loadChildren: () => import('./coach-settings/coach.module').then(m => m.CoachModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'formations',
        canLoad: [],
        loadChildren: () => import('./formations/formations.module').then(m => m.FormationsModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
       {
        path: 'team-settings',
        canLoad: [],
        loadChildren: () => import('./team-settings/team-settings.module').then(m => m.TeamSettingsModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'template',
        canLoad: [],
        loadChildren: () => import('./notification-template/notification-template.module').then(m => m.TemplateModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
      {
        path: 'settings',
        canLoad: [],
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
    ]
  },
  {
    path: 'login',
    canLoad: [],
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
   {
        path: 'dashboard',
        canLoad: [],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RoleGuard],
        data: { roles: [Role.ROLE_ADMIN, Role.ROLE_SALES_MANAGER, Role.ROLE_TEAM_LEADER] }
      },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top', relativeLinkResolution: 'corrected' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { User } from '../../../models/user.model';
import { Role } from '../../../models/types/roles';
import { StoreService } from 'src/app/core/services/store.service';
import { skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  isAdmin = false;
  private user$ = this.storeService.getUser();

  constructor(
    private storeService: StoreService
  ) { }
  ngOnInit() {
    this.user$
      .pipe(skipWhile(res => res === null))
      .subscribe(user => {
        const currentUser = user;
        if (currentUser) {
          this.isAdmin = currentUser.role === Role.ROLE_ADMIN.toString();
        }
      });
  }

}

<div class="date-range-picker">

  <form class="form-inline">
    <div class="form-group hidden">
      <div class="input-group">
        <input readonly name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
          (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
          [startDate]="fromDate" (click)="datepicker.toggle()">
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
        <input readonly #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate"
          [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
          (click)="datepicker.toggle()">
        <div class="input-group-append input-group-text">
          <i class="icon-calendar"></i>
        </div>
      </div>
    </div>
    <div class="form-group ml-2">
      <div class="input-group">
        <input readonly #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
          [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
          (click)="datepicker.toggle()">
        <div class="input-group-append input-group-text">
          <i class="icon-calendar"></i>
        </div>
      </div>
    </div>
  </form>

</div>

<!-- Sidebar content -->
<div class="sidebar-content">

  <!-- Main navigation -->
  <div class="card card-sidebar-mobile">

    <ul class="nav nav-sidebar">

      <!-- Users -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/users']" routerLinkActive="active">
          <i class="icon-users"></i>
          <span>
            {{ 'users.user' | translate }}
          </span>
        </a>
      </li>
      <!-- /Users -->

      <!-- Goals Management -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/goals']" routerLinkActive="active">
          <i class="icon-task"></i>
          <span>
            {{ 'goals.goals_assignment_title' | translate }}
          </span>
        </a>
      </li>
      <!-- /Goals Management -->

      <!-- Competitions Management -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/competitions']" routerLinkActive="active">
          <i class="icon-trophy2"></i>
          <span>
            {{ 'competitions.competitions_assignment_title' | translate }}
          </span>
        </a>
      </li>
      <!-- /Competitions Management -->

       <!-- Notifications Management -->
      <!-- <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/notifications']" routerLinkActive="active">
          <i class="icon-megaphone"></i>
          <span>
           {{ 'notifications.virtual_coach' | translate }}
          </span>
        </a>
      </li> -->
      <!-- /Notificationstions Management -->

      <!-- Coach Management -->
      <!-- <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/coach']" routerLinkActive="active">
          <i class="icon-user-tie"></i>
          <span>
           {{ 'notifications.coach_settings' | translate }}
          </span>
        </a>
      </li> -->
      <!-- /Coach Management -->

       <!-- Formations Management -->
       <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/team-settings']" routerLinkActive="active">
          <i class="icon-dribbble3"></i>
          <span>
           {{ 'formations.formations' | translate }}
          </span>
        </a>
      </li> 
      <!-- /Formations Management -->

       <!-- Dashboard Management -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/dashboard']" routerLinkActive="active">
          <i class="icon-display"></i>
          <span>
           Dashboard
          </span>
        </a>
      </li>
      <!-- /Dashboard Management -->

      <!-- Profile Settings -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/settings/profile']" routerLinkActive="active">
          <i class="icon-profile"></i>
          <span>
            {{'settings.profile_settings' | translate}}
          </span>
        </a>
      </li>
      <!-- /Profile Settings -->

      <!-- Configurations -->
      <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link text-light-grey" [routerLink]="['/settings/configurations']" routerLinkActive="active">
          <i class="icon-gear"></i>
          <span>
            {{'settings.configurations' | translate}}
          </span>
        </a>
      </li>
      <!-- /Configurations -->

      <!-- Reports -->
      <li class="nav-item-header"><a>
          {{ 'reports.reports' | translate }}
        </a></li>

      <!-- User current goals report -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/reports/user-goals-progress']" routerLinkActive="active">
          <i class="icon-stats-bars"></i>
          <span>
            {{ 'goals.goals_progress' | translate }}
          </span>
        </a>
      </li>
      <!-- User current goals report -->

       <!-- User ongoing sales stats summary report -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/reports/users-ongoing-sales-summary']" routerLinkActive="active">
          <i class="icon-stats-dots"></i>
          <span>
            {{ 'reports.ongoing_sales_summary_page_title' | translate }}
          </span>
        </a>
      </li>
      <!-- User ongoing sales stats summary report -->

       <!-- User total stats summary report -->
      <li class="nav-item">
        <a class="nav-link text-light-grey" [routerLink]="['/reports/users-total-summary']" routerLinkActive="active">
          <i class="icon-statistics"></i>
          <span>
            {{ 'reports.total_summary_page_title' | translate }}
          </span>
        </a>
      </li>
      <!-- User total stats summary report -->

      <!-- /Reports -->

    </ul>

  </div>
  <!-- /main navigation -->
</div>
<!-- /sidebar content -->
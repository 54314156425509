import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { User, StatsClass } from '../../models/user.model';
import { ApiService } from './api.service';
import { EndpointsService } from './endpoints.service';
import { StoreService } from './store.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(
        private firestore: AngularFirestore,
        private apiService: ApiService,
        private endpoints: EndpointsService,
    ) {}

        getUserById(uid, crm): Promise < any > {
        return this.firestore.collection(crm).doc(uid).ref.get();
    }

    getUserAccountDetailsListBySupervisorId(uid, crm, role): Observable < any > {
        if (role == 'teamleader'){
            return this.firestore.collection < User > (crm, ref =>
                ref.where('account_details.is_trashed', '==', false)
                .where('account_details.teamleader', '==', uid)
                .orderBy('account_details.created_at', 'desc')).snapshotChanges();
        }
        else if (role == 'salesmanager'){
            return this.firestore.collection < User > (crm, ref =>
                ref.where('account_details.is_trashed', '==', false)
                .where('account_details.salesmanager', '==', uid)
                .orderBy('account_details.created_at', 'desc')).snapshotChanges();
        }
        else{
        return this.firestore.collection < User > (crm, ref =>
            ref.where('account_details.is_trashed', '==', false).where('account_details.is_bot', '==', false).orderBy('account_details.created_at', 'desc')).snapshotChanges();
        }
    }

    getUsersAccountDetailsList(crm): Observable < any > {
        return this.firestore.collection < User > (crm, ref =>
            ref.where('account_details.is_trashed', '==', false).where('account_details.is_bot', '==', false).orderBy('account_details.created_at', 'desc')).snapshotChanges();
    }

    getMappedUserData(userData, id = ''): User {
        const firstName = userData.first_name == null ? '' : userData.first_name;
        const lastName = userData.last_name == null ? '' : userData.last_name;
        const user: User = {
            firstName,
            lastName,
            fullName: firstName + ' ' + lastName,
            picture: userData.picture == null ? '' : userData.picture,
            authId: id,
            email: userData.email == null ? '' : userData.email,
            employeeId: userData.employee_id == null ? '' : userData.employee_id,
            joiningDate: userData.joining_date == null ? '' : userData.joining_date.toDate(),
            shirtNumber: userData.shirt_number == null ? 0 : userData.shirt_number,
            role: userData.role == null ? '' : userData.role,
            teamleader: userData.teamleader == null || userData.teamleader == " " || userData.teamleader == "" ? "" : userData.teamleader,
            salesmanager: userData.salesmanager == null || userData.salesmanager == " " || userData.salesmanager == "" ? "" : userData.salesmanager,
            isTrashed: userData.is_trashed,
            isActive: userData.is_active == null ? true : userData.is_active,
            receiveGoalsReport: userData.receive_goals_report,
            receiveDailyProgressReport: userData.receive_daily_progress_report == null ? false : userData.receive_daily_progress_report,
            receiveWeeklyAwardsReport: userData.receive_weekly_awards_report == null ? false : userData.receive_weekly_awards_report,
            group: "Säljare",
            // group: this.checkIfHunter(userData.joining_date.toDate()) == true ? "Hunters" : "Säljare",
            stars: userData.star_rating == null ? 0 : userData.star_rating,
            isBot: userData.is_bot == null ? false : userData.is_bot,
            assignees: userData.assignees == null ? [] : userData.assignees,
            trend: userData.trend_start_date == null ? null : this.getDifferenceInDays(new Date(), userData.trend_start_date.toDate()) > 7 ? null : userData.trend,
            stats: null,
        };
        return user;
    }

    getAllUsersDataList(crm): Observable < any > {
        if (crm == "users")
            return this.firestore.collection < User > ('users', ref => ref.where('account_details.is_trashed', '==', false).where('account_details.is_bot', '==', false).where('account_details.role', 'in',  ["salesperson", "teamleader"])
                .orderBy('stats.season_stats.sales', 'desc').orderBy('stats.season_stats.calls_made', 'desc')).snapshotChanges();
        return this.firestore.collection < User > ("dynamics", ref => ref.where('account_details.is_trashed', '==', false).where('account_details.is_bot', '==', false).where('account_details.role', 'in', ["salesperson", "teamleader"])
            .orderBy('stats.season_stats.sales', 'desc')).snapshotChanges();
    }

    getUserCallsById(uid): Promise < any > {
        return this.firestore.collection('calls_statistics').doc(uid).ref.get();
    }

    getMappedUserStats(userData, id = ''): User {
        const firstName = userData.account_details.first_name == null ? '' : userData.account_details.first_name;
        const lastName = userData.account_details.last_name == null ? '' : userData.account_details.last_name;
        const stats: StatsClass = {
            callsMade: userData.stats.season_stats.calls_made == null ? 0 : userData.stats.season_stats.calls_made,
            bookedMeetings: userData.stats.season_stats.booked_meetings == null ? 0 : userData.stats.season_stats.booked_meetings,
            meetingsHeld: userData.stats.season_stats.meetings_held == null ? 0 : userData.stats.season_stats.meetings_held,
            ongoingDeals: userData.stats.season_stats.ongoing_deals == null ? 0 : userData.stats.season_stats.ongoing_deals,
            newCustomerContracts: userData.stats.season_stats.new_customer_contracts == null ? 0 : userData.stats.season_stats.new_customer_contracts,
            existingCustomerContracts: userData.stats.season_stats.existing_customer_contracts == null ? 0 : userData.stats.season_stats.existing_customer_contracts,
            salesInMillions: userData.stats.season_stats.sales == null ? " " : (userData.stats.season_stats.sales / 1000000).toLocaleString('sv-SE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            sales: userData.stats.season_stats.sales == null ? " " : (userData.stats.season_stats.sales).toLocaleString('sv-SE', { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
            numberOfContracts: userData.stats.season_stats.number_of_contracts == null ? 0 : userData.stats.season_stats.number_of_contracts,
            numberOfActiveClients: userData.stats.season_stats.number_of_active_clients == null ? 0 : userData.stats.season_stats.number_of_active_clients,
        };
        const user: User = {
            firstName,
            lastName,
            fullName: userData.account_details.first_name[0].toUpperCase() + '. ' + lastName,
            picture: userData.account_details.picture == null ? '' : userData.account_details.picture,
            authId: id,
            email: userData.account_details.email == null ? '' : userData.account_details.email,
            employeeId: userData.account_details.employee_id == null ? '' : userData.account_details.employee_id,
            joiningDate: userData.account_details.joining_date == null ? '' : userData.account_details.joining_date.toDate(),
            shirtNumber: userData.account_details.shirt_number == null ? 0 : userData.account_details.shirt_number,
            role: userData.account_details.role == null ? '' : userData.account_details.role,
            teamleader: userData.account_details.teamleader == null || userData.account_details.teamleader == " " || userData.account_details.teamleader == "" ? "" : userData.account_details.teamleader,
            salesmanager: userData.account_details.salesmanager == null || userData.account_details.salesmanager == " " || userData.account_details.salesmanager == "" ? "" : userData.account_details.salesmanager,
            isTrashed: userData.account_details.is_trashed,
            isActive: userData.account_details.is_active == null ? true : userData.account_details.is_active,
            receiveGoalsReport: userData.account_details.receive_goals_report,
            receiveDailyProgressReport: userData.account_details.receive_daily_progress_report == null ? false : userData.account_details.receive_daily_progress_report,
            receiveWeeklyAwardsReport: userData.account_details.receive_weekly_awards_report == null ? false : userData.account_details.receive_weekly_awards_report,
            group: "Säljare",
            // group: this.checkIfHunter(userData.account_details.joining_date.toDate()) == true ? "Hunters" : "Säljare",
            stars: userData.account_details.star_rating == null ? 0 : userData.account_details.star_rating,
            isBot: userData.account_details.is_bot == null ? false : userData.account_details.is_bot,
            assignees: userData.account_details.assignees == null ? [] : userData.account_details.assignees,
            trend: userData.account_details.trend_start_date == null ? null : this.getDifferenceInDays(new Date(), userData.account_details.trend_start_date.toDate()) > 7 ? null : userData.account_details.trend,
            stats,
            
        };
        return user;
    }

    setUserData(userData: User, uid: string, crm) {
        const userRef: AngularFirestoreDocument < any > = this.firestore.collection(crm).doc(`${uid}`);
        return userRef.set(userData, {
            merge: true
        });
    }

    disableUser(params) {
        return this.apiService.get < any > (this.endpoints.getDisableUserUrl(), params);
    }

    deactivateUser(params) {
        return this.apiService.get < any > (this.endpoints.getDeactivateUserUrl(), params);
    }

    getUserGoalsProgressReport() {
        return this.apiService.get < any > (this.endpoints.getUserGoalsProgressUrl());
    }

    createUser(body) {
        return this.apiService.post < any > (this.endpoints.getCreateUserUrl(), body);
    }

    updateUser(body) {
        return this.apiService.post < any > (this.endpoints.getUpdateUserUrl(), body);
    }

    updateUserProfile(body) {
        return this.apiService.post < any > (this.endpoints.getUpdateUserProfileUrl(), body);
    }

    updateUserProfileSettings(body) {
        return this.apiService.post < any > (this.endpoints.getUpdateUserUrl(), body);
    }

    syncCompanies(body) {
        return this.apiService.post < any > (this.endpoints.getSyncCompanies(), body);
    }

    updatePassword(body) {
        return this.apiService.post < any > (this.endpoints.getChangePasswordUrl(), body);
    }

    checkIfHunter(joiningDate) {
        const milliSecondsInThreeMonths = 7776000000;
        const milliSecondsSinceJoining = new Date().getTime() - new Date(joiningDate).getTime();
        if (milliSecondsSinceJoining <= milliSecondsInThreeMonths) {
            return true;
        }
        return false;
    }

    getTeamAData(): Promise < any > {
        return this.firestore.collection('team_management').doc('team_A').ref.get();
    }
    getDifferenceInDays(currentDate, trendDate) {
        var difference_In_Time = currentDate.getTime() - trendDate.getTime();

        // To calculate the no. of days between two dates 
        var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
        return difference_In_Days;
    }
    getActiveCRM(): Promise < any > {
        return this.firestore.collection('crm').doc('data_source').ref.get();
    }
}
<!-- Main navbar -->
<app-navbar class="bg-white navbar navbar-expand-md"></app-navbar>
<!-- /main navbar -->

<!-- Page content -->
<div class="bg-start-elvan-body page-content">

  <!-- Main sidebar -->
  <app-sidebar class="sidebar sidebar-expand-md"></app-sidebar>
  <!-- /main sidebar -->

  <!-- Main content -->
  <div class="content-wrapper">

    <!-- Content area -->
    <div class="content">
      <router-outlet></router-outlet>
    </div>

  </div>
  <!-- /main content -->

</div>
<!-- /page content -->

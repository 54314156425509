<div class="nav-item dropdown dropdown-user" ngbDropdown>
    <span class="navbar-nav-link dropdown-toggle" ngbDropdownToggle>
      {{labelText}}
    </span>

    <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <a class="dropdown-item" (click)="onOptionSelection(null, null)"><i class="icon-copy3"></i>
        {{ defaultLabelText }}
      </a>
      <a class="dropdown-item">
        <app-date-range-picker (rangeSelected)="onDateRangeSelected($event)">
        </app-date-range-picker >
      </a>
    </div>
  </div>
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { StoreService } from '../../core/services/store.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class SharedUtilsService {

  user$ = this.store.getUser();
  userId;

  private subscriptions$: Subscription[] = [];
  private isExportingCSV = new BehaviorSubject<boolean>(false);

  constructor(
    private toastr: ToastrService,
    private store: StoreService,
  ) { 
    this.subscriptions$.push(
      this.user$.subscribe(res => {
        if (res) {
          this.userId = res.authId;
        }
      })
    );
  }

  handleNetworkCallError(error) {
    if (error) {
      if (error.status === 401 || error.status === 403) {
        this.toastr.error('Sorry! you don\'t have the permission for this operation');
      } else if (error.error === 'invalid_grant' && error.error_description === 'Bad credentials') {
        this.toastr.error('Incorrect email or password');
      } else if (error.error) {
        this.toastr.error(error.error);
      } else if (error.message) {
        this.toastr.error(error.message);
      }
    } else {
      this.toastr.error('Something went wrong');
    }
    console.error(error);
  }

  exportAsCSV(url, fileName, body = {}) {
    const req = new XMLHttpRequest();
    req.open('POST', url, true, );
    req.responseType = 'blob';
    req.setRequestHeader('authorization',  this.userId);

    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE version
          const blob = new Blob([req.response], { type: 'text/csv' });
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          // Firefox and chrome version
          const file = new File([req.response], fileName, { type: 'text/csv' });

          // Add an invisible element and use that to download csv file/blob
          let element;
          element = document.createElement('a');
          document.body.appendChild(element);
          element.style = 'display: none';
          element.href = URL.createObjectURL(file);
          element.download = fileName;
          element.click();
          window.URL.revokeObjectURL(url);
          this.setIsExportingCSV(false);
        }
      }
      if (req.status > 299 && req.readyState === 4) {
        this.toastr.error('Error in fetching report, please try again');
        this.setIsExportingCSV(false);
        return false;
      }
    };
    req.send(JSON.stringify(body));
    return true;
  }
  /**
   * Change loader status
   */
  setIsExportingCSV(IsExportingCSV: boolean): void {
    this.isExportingCSV.next(IsExportingCSV);
  }

  /**
   * Returns only the observable part of IsExportingCSVSubject
   */
  getIsExportingCSV(): Observable<boolean> {
    return this.isExportingCSV.asObservable();
  }
}
